import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const [tags, setTags] = useState([])

  useEffect(() => {
    const loadTags = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.GATSBY_STRAPI_API_URL}/tags`
        )
        setTags(data)
      } catch (e) {
        //
      }
    }
    loadTags()
  }, [])
  return <AppContext.Provider value={{ tags }}>{children}</AppContext.Provider>
}
